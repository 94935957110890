export const CHECKOUT_PAGE_COUPON = {
  NAME: '',
  UNIT_PRICE: 0,
  TYPE: '',
};

export const CHECKOUT_PAGE_APPLY_COUPON = {
  STATUS_CODE: 200,
};

export const APPLY_COUPON = {
  LINE_ITEM_DISCOUNT: 'line-item/coupon-discount',
};